<script lang="ts" setup>
import AppWeight from "~/components/app/AppWeight.vue";
import OnboardingStickyButtonWrapper from "~/components/onboarding/OnboardingStickyButtonWrapper.vue";
import type { CurrentWeightScreen } from "~/types/onboarding/onboardingScreen";
import type { RegistrationParams, UnitMass } from "~/types/onboarding/registrationParams";

const { defaultUnitMass } = useNuxtApp().$i18n;

interface Props {
  screen: CurrentWeightScreen;
  registrationParams: RegistrationParams;
}

const props = defineProps<Props>();

interface Emits {
  (
    e: "next",
    value: {
      params: {
        startWeight: number;
        unitMass: UnitMass;
      };
      nextScreenId: string;
    },
  ): void;
}

const emit = defineEmits<Emits>();

const { getNextScreenIdByGoal } = useConditionalNextSteps(props);

const { kg, kgAsString, lbAsString, unitMass, validate, kgInvalid, lbInvalid, error, canContinue } = useWeight(
  props.registrationParams.startWeight || null,
  props.registrationParams.unitMass || defaultUnitMass.value,
);

function next() {
  const hasError = validate();
  if (hasError) return;
  const nextScreenId = getNextScreenIdByGoal();
  emit("next", {
    params: {
      startWeight: kg.value as number,
      unitMass: unitMass.value,
    },
    nextScreenId,
  });
}
</script>

<template>
  <OnboardingStickyButtonWrapper
    :button-text="$t('registration.general.next')"
    :button-disabled="!canContinue"
    :enable-additional-button="true"
    @button-click="next"
  >
    <h1>
      {{ $t("registration.current_weight.headline") }}
    </h1>
    <div class="mb-12">
      {{ $t("registration.current_weight.teaser") }}
    </div>
    <AppWeight
      v-model:kg="kgAsString"
      v-model:lb="lbAsString"
      v-model:unitMass="unitMass"
      :kg-invalid="kgInvalid"
      :lb-invalid="lbInvalid"
      :error-message="error"
      @submit="next"
    />
  </OnboardingStickyButtonWrapper>
</template>
