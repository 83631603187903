<script lang="ts" setup>
import { ref } from "vue";
const config = useRuntimeConfig();
interface Props {
  images: string[];
  srcImageWidth: number;
  stackMaxWidth: number;
  alt: string;
}

const props = defineProps<Props>();

const selectedKey = ref<number | null>();

function select(key: number) {
  selectedKey.value = key;
}

function fullUrl(src: string) {
  return `${config.public.imageBaseUrl}/${src}.jpg?w=${props.srcImageWidth}&h=${props.srcImageWidth}&crop-to-fit`;
}
</script>

<template>
  <div
    class="relative block aspect-[1.35]"
    :style="{
      maxWidth: stackMaxWidth ? `${stackMaxWidth}px` : undefined,
    }"
  >
    <img
      v-for="(src, key) in images"
      :key="key"
      :class="[
        'absolute max-w-[50%] rounded-xl shadow-xl',
        {
          'left-[52%] top-[5%] z-30 -translate-x-1/2 rotate-[4deg] transform': key === 0,
          'left-[30%] top-[20%] z-20 -translate-x-1/2 -rotate-[12deg] scale-90 transform': key === 1,
          'left-[68%] top-[27%] z-10 -translate-x-1/2 rotate-[16deg] scale-[0.84] transform': key === 2,
        },
      ]"
      :src="fullUrl(src)"
      :alt="alt"
      @click="select(key)"
    />
  </div>
</template>
