<script lang="ts" setup>
import AppBackgroundImage from "~/components/app/AppBackgroundImage.vue";
import OnboardingStickyButtonWrapper from "~/components/onboarding/OnboardingStickyButtonWrapper.vue";
import type { InfoListScreen } from "~/types/onboarding/onboardingScreen";
import type { RegistrationParams } from "~/types/onboarding/registrationParams";

interface Props {
  screen: InfoListScreen;
}

const props = defineProps<Props>();

interface Emits {
  (
    e: "next",
    value: {
      params: Partial<RegistrationParams>;
      nextScreenId: string;
    },
  ): void;
}

const emit = defineEmits<Emits>();

function next() {
  emit("next", {
    params: {},
    nextScreenId: props.screen.nextStep,
  });
}
</script>

<template>
  <OnboardingStickyButtonWrapper :button-text="$t(screen.nextButtonTranslationKey)" @button-click="next">
    <AppBackgroundImage :src="screen.imageUrl" :size="screen.imageSize" />
    <div class="container flex justify-center px-bs-container">
      <div class="py-6">
        <h1>
          {{ $t(screen.titleTranslationKey) }}
        </h1>
        <div class="rounded-xl bg-white text-yz-gray-800 shadow-sm">
          <div class="flex flex-col content-center gap-4 p-4">
            <div v-for="(option, key) in screen.infoList" :key="key" class="flex gap-4">
              <div>
                <span>{{ option.emoji }}</span>
              </div>
              <div>{{ $t(option.translationKey) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </OnboardingStickyButtonWrapper>
</template>
