<script setup lang="ts">
import Text from "~/components/atoms/Text.vue";

const onboardingStore = useOnboardingStore();

const { foodName } = storeToRefs(onboardingStore);
</script>

<template>
  <div v-if="foodName">
    <div class="flex gap-4 rounded-t-xl bg-yz-green-100 p-5">
      <img alt="" src="https://assets.yazio.com/frontend/images/web-funnels/icons/food-calculator-icon.svg" />
      <div class="flex flex-col">
        <Text bold>{{ foodName }}</Text>
        <Text size="small" translation-key="text.webfunnel.food_traffic.banner.heading"></Text>
      </div>
    </div>

    <div class="rounded-b-xl bg-yz-green-200 p-5">
      {{ $t("text.webfunnel.food_traffic.banner.subheading") }}
    </div>
  </div>
</template>
