import type { Instructions } from "~/types/instructions";

export const onboardingTest: Instructions = {
  start: "main-goal",
  screens: [
    {
      type: "reason",
      id: "main-goal",
      eventName: "onboarding.encouraging_flow.goal.main_goal",
      titleTranslationKey: "onboarding.encouraging_flow.goal.main_goal.question",
      fallbackOption: {
        emoji: "💬",
        translationKey: "onboarding.encouraging_flow.goal.main_goal.other",
        trackingName: "onboarding.encouraging_flow.goal.main_goal.other",
        nextStep: "reason"
      },
      options: [
        {
          emoji: "🔥",
          translationKey: "onboarding.encouraging_flow.goal.main_goal.lose_weight",
          trackingName: "onboarding.encouraging_flow.goal.main_goal.lose_weight",
          nextStep: {
            condition: {
              type: "overall_goal",
              branch: "LooseWeight"
            },
            nextStep: "reason"
          }
        },
        {
          emoji: "\uD83D\uDC40",
          translationKey: "onboarding.encouraging_flow.goal.main_goal.maintain_weight",
          trackingName: "onboarding.encouraging_flow.goal.main_goal.maintain_weight",
          nextStep: {
            condition: {
              type: "overall_goal",
              branch: "MaintainWeight"
            },
            nextStep: "reason"
          }
        }
      ]
    },
    {
      type: "single_choice",
      id: "reason",
      eventName: "onboarding.encouraging_flow.goal.reason",
      titleTranslationKey: "onboarding.encouraging_flow.goal.reason.question",
      options: [
        {
          emoji: "😎",
          translationKey: "onboarding.encouraging_flow.goal.reason.confidence",
          trackingName: "onboarding.encouraging_flow.goal.reason.confidence",
          nextStep: "additional-goal"
        },
        {
          emoji: "💚",
          translationKey: "onboarding.encouraging_flow.goal.reason.health",
          trackingName: "onboarding.encouraging_flow.goal.reason.health",
          nextStep: "additional-goal"
        }
      ]
    },
    {
      type: "multi_choice",
      id: "additional-goal",
      eventName: "onboarding.encouraging_flow.goal.additional_goal",
      titleTranslationKey: "onboarding.encouraging_flow.goal.additional_goal.question",
      options: [
        {
          emoji: "🍲",
          translationKey: "onboarding.encouraging_flow.goal.additional_goal.food",
          trackingName: "onboarding.encouraging_flow.goal.additional_goal.food"
        },
        {
          emoji: "🧑‍🍳",
          translationKey: "onboarding.encouraging_flow.goal.additional_goal.cooking",
          trackingName: "onboarding.encouraging_flow.goal.additional_goal.cooking"
        }
      ],
      nextButtonTranslationKey: "system.general.button.next",
      nextStep: "challenges-affirmation"
    },
    {
      type: "affirmation",
      id: "challenges-affirmation",
      eventName: "onboarding.encouraging_flow.past.challenges_affirmation",
      titleTranslationKey: "onboarding.encouraging_flow.past.challenges_affirmation.title",
      captionTranslationKey: "onboarding.encouraging_flow.past.challenges_affirmation.description",
      imageSize: "LARGE",
      imageUrl: "app/misc/illustrations/welcome_greetings.light",
      nextButtonTranslationKey: "system.general.button.lets_go",
      nextStep: "gender"
    },
    {
      type: "sex",
      nextStep: "birthday",
      id: "gender",
      eventName: "onboarding.encouraging_flow.personal.gender",
      nextStepsWithCondition: []
    },
    {
      type: "birthday",
      nextStep: "height",
      id: "birthday",
      eventName: "onboarding.encouraging_flow.personal.birthday"
    },
    {
      type: "height",
      nextStep: "activity-level",
      id: "height",
      eventName: "onboarding.encouraging_flow.personal.height"
    },
    {
      type: "activity_level",
      nextStep: "current-weight",
      id: "activity-level",
      eventName: "onboarding.encouraging_flow.personal.activity_level"
    },
    {
      type: "current_weight",
      nextStep: "goal-weight",
      nextStepsWithCondition: [
        {
          nextStep: "goal-weight",
          condition: {
            type: "overall_goal",
            branch: "LooseWeight"
          }
        }
      ],
      id: "current-weight",
      eventName: "onboarding.encouraging_flow.personal.current_weight"
    },
    {
      type: "target_weight",
      nextStep: "dietary-preferences",
      nextStepsWithCondition: [
        {
          nextStep: "dietary-preferences",
          condition: {
            type: "overall_goal",
            branch: "LooseWeight"
          }
        }
      ],
      id: "goal-weight",
      eventName: "onboarding.encouraging_flow.personal_goal.goal_weight"
    },
    {
      type: "diet",
      nextStep: "recipes-affirmation",
      id: "dietary-preferences",
      eventName: "onboarding.encouraging_flow.diet.dietary_preferences"
    },
    {
      type: "illustrations_recipes",
      nextStep: "event-date",
      id: "recipes-affirmation",
      eventName: "onboarding.encouraging_flow.diet.recipes_affirmation",
      conditionalNextSteps: [
        {
          nextStep: "event-date",
          condition: {
            type: "overall_goal",
            branch: "GainWeight"
          }
        }
      ]
    },
    {
      type: "date",
      id: "event-date",
      eventName: "onboarding.encouraging_flow.personal_goal.event_date",
      titleTranslationKey: "onboarding.encouraging_flow.personal_goal.event_date.title",
      nextButtonTranslationKey: "system.general.button.next",
      nextStep: "personalized-program-affirmation"
    },
    {
      type: "info_list",
      id: "personalized-program-affirmation",
      eventName: "onboarding.encouraging_flow.plan.personalized_program_affirmation",
      titleTranslationKey: "onboarding.encouraging_flow.plan.personalized_program_affirmation.title",
      imageSize: "MEDIUM",
      imageUrl: "app/misc/illustrations/onboarding/onboarding.encouraging_flow.plan.personalized_program_affirmation.light",
      infoList: [
        {
          translationKey: "onboarding.encouraging_flow.plan.personalized_program_affirmation.results",
          emoji: "⛳"
        },
        {
          translationKey: "onboarding.encouraging_flow.plan.personalized_program_affirmation.habits",
          emoji: "🚀"
        }
      ],
      nextButtonTranslationKey: "system.general.button.lets_go",
      nextStep: "load-plan"
    },
    {
      type: "loading_personal_plan",
      nextStep: "end",
      id: "load-plan",
      eventName: "onboarding.encouraging_flow.plan.load_plan_1"
    }
  ]
}
