<script lang="ts" setup>
import OnboardingBtn from "~/components/onboarding/OnboardingBtn.vue";
import type { NextStep, ReasonScreen, ScreenOptionWithNextStep } from "~/types/onboarding/onboardingScreen";
import { goalMap, type GoalTypeAbbr } from "~/types/onboarding/onboardingGoal";
import OnboardingCalculatorBanner from "~/components/onboarding/OnboardingCalculatorBanner.vue";
import OnboardingCalculatorDisclaimer from "~/components/onboarding/OnboardingCalculatorDisclaimer.vue";

interface Props {
  screen: ReasonScreen;
}

const props = defineProps<Props>();

interface Emits {
  (
    e: "next",
    value: {
      params: { reason: GoalTypeAbbr | null };
      nextScreenId: string;
    },
  ): void;
}

const { t } = useNuxtApp().$i18n;
const emit = defineEmits<Emits>();
const { addActionEvent } = useTrackingStore();
const calculatorFunnelStore = useCalculatorFunnelStore();

const sortedOptions = computed(() => {
  const recommendedGoal = calculatorFunnelStore.getRecommendedGoal();
  if (!recommendedGoal) {
    return props.screen.options;
  }
  const options = props.screen.options;
  return options.sort((a, b) => {
    if (a.translationKey.includes(recommendedGoal ?? "")) {
      return -1;
    }
    if (b.trackingName.includes(recommendedGoal ?? "")) {
      return 1;
    }
    return 0;
  });
});

function selectFallbackOption() {
  emit("next", {
    params: { reason: null },
    nextScreenId: props.screen.fallbackOption.nextStep,
  });
}

function getStatusText(translationKey: string) {
  const translationKeyMap: Record<string, GoalTypeAbbr> = {
    "onboarding.encouraging_flow.goal.main_goal.lose_weight": "lose",
    "onboarding.encouraging_flow.goal.main_goal.maintain_weight": "maintain",
    "onboarding.encouraging_flow.goal.main_goal.gain_weight": "gain",
  };

  if (
    calculatorFunnelStore.getRecommendedGoal() &&
    calculatorFunnelStore.getRecommendedGoal() === translationKeyMap[translationKey]
  ) {
    return t("app.profunnel.calculator_funnel.bmi.recommended");
  }
}

function selectOption(option: ScreenOptionWithNextStep<NextStep>) {
  const goalType = option.nextStep.condition.branch;
  const reason = goalMap[goalType ?? "LooseWeight"];

  emit("next", {
    params: { reason },
    nextScreenId: option.nextStep.nextStep,
  });

  addActionEvent({
    name: props.screen.eventName,
    selection: [option.trackingName],
    screenType: props.screen.type,
  });
}
</script>

<template>
  <div>
    <OnboardingCalculatorBanner class="mt-4" />

    <h1>
      {{ $t(screen.titleTranslationKey) }}
    </h1>
    <div class="mb-4 grid gap-4">
      <OnboardingBtn
        v-for="(option, key) in sortedOptions"
        :key="key"
        :emoji="option.emoji"
        :translation-key="option.translationKey"
        :status-text="getStatusText(option.translationKey)"
        @click="selectOption(option)"
      />
      <OnboardingBtn
        v-if="screen.fallbackOption"
        :emoji="screen.fallbackOption.emoji"
        :translation-key="screen.fallbackOption.translationKey"
        @click="selectFallbackOption"
      />
    </div>

    <OnboardingCalculatorDisclaimer class="my-5" />
  </div>
</template>
