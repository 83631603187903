<script lang="ts" setup>
import type { RegistrationParams } from "~/types/onboarding/registrationParams";
import type { Instructions } from "~/types/instructions";

import { onboardingPartner } from "~/resources/onboarding/onboarding-partner";
import { onboardingShort } from "~/resources/onboarding/onboarding-short";
import { onboardingTest } from "~/resources/onboarding/onboarding-test";
import { onboardingWelcome } from "~/resources/onboarding/onboarding-welcome";
import { onboardingCalculator } from "~/resources/onboarding/onboarding-calculator";
import { useOnboardingStore } from "~/stores/useOnboardingStore";
import AppProgress from "~/components/app/AppProgress.vue";
import OnboardingDiscountBannerSecondPartnerScreen from "~/components/onboarding/OnboardingDiscountBannerSecondPartnerScreen.vue";

const { enabledFunnels } = useAppConfig();
const { t } = useNuxtApp().$i18n;

useHead({
  title: t("app.prosignup.seo_title"),
});

definePageMeta({
  key: "onboarding",
  middleware: ["redirect-authenticated-users", "check-calculator-parameters"],
});

const defaultInstructionsIdentifier = "start";
const onboardingStore = useOnboardingStore();
const route = useRoute();

const { addImpressionEvent } = useTrackingStore();
const { skippedOnboarding } = storeToRefs(onboardingStore);

const {
  updateRegistrationParams,
  screen,
  screenComponent,
  registrationParams,
  screenHasWideWrapper,
  screenUseTransparentBackground,
  screenIndex,
  getProgress,
} = useOnboarding(await getInstructions());

async function getInstructions() {
  const funnels: Record<string, Instructions> = {
    partner: onboardingPartner,
    short: onboardingShort,
    test: onboardingTest,
    welcome: onboardingWelcome,
    calculator: onboardingCalculator,
  };

  if (!route.params.instructions || route.params.instructions === defaultInstructionsIdentifier) {
    return funnels.short;
  }

  const requestedFunnel: string = Array.isArray(route.params.instructions)
    ? route.params.instructions[0]
    : route.params.instructions;

  if (!enabledFunnels.includes(requestedFunnel) || !funnels[requestedFunnel]) {
    await navigateTo("/onboarding");
    return funnels.short;
  }

  return funnels[requestedFunnel];
}

const { skipPersonalizedMotivationScreen } = useAB();

async function doNext({ nextScreenId, params }: { nextScreenId: string; params: Partial<RegistrationParams> }) {
  updateRegistrationParams(params);

  if (nextScreenId === "personalized-motivation") {
    if (skipPersonalizedMotivationScreen.value) {
      return navigateTo(`/onboarding/${route.params.instructions || defaultInstructionsIdentifier}/load-plan`);
    }
  }

  if (nextScreenId === "end") {
    updateRegistrationParams({ incomplete: false });

    return navigateTo({
      path: "/onboarding/checkout",
      query: {
        instructions: route.params.instructions || defaultInstructionsIdentifier,
      },
    });
  }

  await navigateTo(`/onboarding/${route.params.instructions || defaultInstructionsIdentifier}/${nextScreenId}`);
}

function trackScreenImpression() {
  addImpressionEvent({ name: screen.value.eventName });
}

onMounted(() => {
  skippedOnboarding.value = false;
  trackScreenImpression();
});

watch(screen, () => {
  trackScreenImpression();
});
</script>

<template>
  <transition name="fade" mode="out-in">
    <div
      :key="screenHasWideWrapper.toString()"
      class="flex flex-1 flex-col"
      :class="{
        'bg-mobile-blurred-background bg-cover bg-top sm:bg-desktop-blurred-background': screenUseTransparentBackground,
      }"
    >
      <div class="flex flex-1 flex-col">
        <OnboardingDiscountBannerSecondPartnerScreen
          v-if="route.params.instructions === 'partner' && screenIndex === 1"
        />

        <div
          class="flex flex-1 justify-center"
          :class="[screenHasWideWrapper ? 'mx-auto w-full pb-4' : 'container px-bs-container']"
        >
          <section
            class="flex flex-col"
            :class="[screenHasWideWrapper ? 'w-full max-w-full' : 'w-full max-w-full lg:w-7/12']"
          >
            <AppProgress
              v-if="!screenUseTransparentBackground && !screenHasWideWrapper"
              :progress="getProgress()"
              class="mt-6"
            />
            <transition name="fade" mode="out-in">
              <component
                :is="screenComponent"
                :screen="screen"
                :registration-params="registrationParams"
                :has-wide-wrapper="screenHasWideWrapper"
                class="flex-1"
                @next="doNext"
              />
            </transition>
          </section>
        </div>
      </div>
    </div>
  </transition>
</template>
