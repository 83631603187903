import { storeToRefs } from "pinia";
import type { OnboardingScreen } from "~/types/onboarding/onboardingScreen";
import type { Instructions } from "~/types/instructions";

import OnboardingSingleChoice from "~/components/onboarding/screens/OnboardingSingleChoice.vue";
import OnboardingMultiChoice from "~/components/onboarding/screens/OnboardingMultiChoice.vue";
import OnboardingReason from "~/components/onboarding/screens/OnboardingReason.vue";
import OnboardingAffirmation from "~/components/onboarding/screens/OnboardingAffirmation.vue";
import OnboardingSex from "~/components/onboarding/screens/OnboardingSex.vue";
import OnboardingBirthday from "~/components/onboarding/screens/OnboardingBirthday.vue";
import OnboardingHeight from "~/components/onboarding/screens/OnboardingHeight.vue";
import OnboardingCurrentWeight from "~/components/onboarding/screens/OnboardingCurrentWeight.vue";
import OnboardingActivityLevel from "~/components/onboarding/screens/OnboardingActivityLevel.vue";
import OnboardingTargetWeight from "~/components/onboarding/screens/OnboardingTargetWeight.vue";
import OnboardingEventDate from "~/components/onboarding/screens/OnboardingEventDate.vue";
import OnboardingInfoList from "~/components/onboarding/screens/OnboardingInfoList.vue";
import OnboardingDiet from "~/components/onboarding/screens/OnboardingDiet.vue";
import OnboardingPartner from "~/components/onboarding/screens/OnboardingPartner.vue";
import OnboardingIllustrationsRecipes from "~/components/onboarding/screens/OnboardingIllustrationsRecipes.vue";
import OnboardingVoucherCode from "~/components/onboarding/screens/OnboardingVoucherCode.vue";
import OnboardingAgeRange from "~/components/onboarding/screens/OnboardingAgeRange.vue";
import OnboardingInfoListImage from "~/components/onboarding/screens/OnboardingInfoListImage.vue";
import OnboardingAppLoadingScreen from "~/components/onboarding/screens/OnboardingAppLoadingScreen.vue";

export function useOnboarding(instructions: Instructions) {
  const { $pinia } = useNuxtApp();

  const onboardingStore = useOnboardingStore($pinia);
  const route = useRoute();
  const featureFlags = useFeatureFlags();

  const { updateRegistrationParams } = onboardingStore;
  const { registrationParams } = storeToRefs(onboardingStore);

  const screenId = computed(() => {
    return route.params.id || instructions.start;
  });

  const screens: { [key: string]: OnboardingScreen } = {};
  instructions.screens.forEach((item) => {
    screens[item.id] = item;
  });

  const componentMap: { [key: string]: Component } = {
    single_choice: OnboardingSingleChoice,
    multi_choice: OnboardingMultiChoice,
    reason: OnboardingReason,
    affirmation: OnboardingAffirmation,
    sex: OnboardingSex,
    birthday: OnboardingBirthday,
    height: OnboardingHeight,
    current_weight: OnboardingCurrentWeight,
    activity_level: OnboardingActivityLevel,
    target_weight: OnboardingTargetWeight,
    loading_personal_plan: OnboardingAppLoadingScreen,
    date: OnboardingEventDate,
    info_list: OnboardingInfoList,
    info_list_image: OnboardingInfoListImage,
    diet: OnboardingDiet,
    illustrations_recipes: OnboardingIllustrationsRecipes,
    partner: OnboardingPartner,
    voucher_code: OnboardingVoucherCode,
    age_range: OnboardingAgeRange,
  };

  const screen = computed<OnboardingScreen>(() => {
    const _screen = screens[screenId.value as string];
    if (!_screen) {
      throw new Error(`No screen config found for id: ${screenId.value}`);
    }
    return _screen;
  });

  const screenComponent = computed<Component>(() => {
    const _screenComponent = componentMap[screen.value.type];
    if (!_screenComponent) {
      throw new Error(`No component found for type: ${screen.value.type}`);
    }
    return _screenComponent;
  });

  const screenHasWideWrapper = computed(() =>
    ["affirmation", "loading_personal_plan", "info_list", "illustrations_recipes"].includes(screen.value.type),
  );

  const screenUseTransparentBackground = computed(() => screen.value.config?.useTransparentBackground || false);

  const screensNeededForCalculation = computed(() => {
    let screens = [...instructions.screens];

    screens = screens.filter((screen) => screen.type !== "loading_personal_plan");

    if (featureFlags.personalized_motivation_screen.isOff({ silent: true }).value) {
      screens = screens.filter((screen) => screen.id !== "personalized-motivation" && screen.type !== "affirmation");
    } else {
      screens = screens.filter((screen, index, self) => {
        if (screen.id.startsWith("personalized-affirmation-")) {
          // Remove all affirmation screens except the first one
          return self.findIndex((s) => s.id.startsWith("personalized-affirmation-")) === index;
        }
        return true;
      });
    }

    return screens;
  });

  const screenIndex = computed(() =>
    screensNeededForCalculation.value.findIndex((screen) => screen.id === screenId.value),
  );

  function getProgress() {
    const totalScreens = screensNeededForCalculation.value.length;

    // Special case for the main goal screen when accessed from the calculator
    if (screenId.value === "main-goal" && route.fullPath.endsWith("calculator")) {
      return Math.round((1 / totalScreens) * 100);
    }

    return Math.round((screenIndex.value / totalScreens) * 100);
  }

  return {
    screen,
    screenComponent,
    screens,
    registrationParams,
    updateRegistrationParams,
    screenHasWideWrapper,
    screenUseTransparentBackground,
    screenIndex,
    getProgress,
  };
}
