import { computed } from "vue";
import { useFeatureFlags } from "~/composables/useFeatureFlags";

export function useAB() {
  const featureFlags = useFeatureFlags();

  const skipPersonalizedMotivationScreen = computed(() => featureFlags.personalized_motivation_screen.isOff().value);

  const showPartnerFunnelSecondPageDiscountBanner = computed(
    () => featureFlags.partner_funnel_second_page_discount_banner.isOn().value,
  );

  return {
    skipPersonalizedMotivationScreen,
    showPartnerFunnelSecondPageDiscountBanner,
  };
}
