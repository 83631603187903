<script lang="ts" setup>
import OnboardingBtn from "~/components/onboarding/OnboardingBtn.vue";
import type { DietScreen } from "~/types/onboarding/onboardingScreen";
import type { Diet } from "~/types/onboarding/registrationParams";

interface Props {
  screen: DietScreen;
}

const props = defineProps<Props>();

interface Emits {
  (
    e: "next",
    value: {
      params: { diet: Diet };
      nextScreenId: string;
    },
  ): void;
}

const emit = defineEmits<Emits>();

function selectOption(diet: Diet) {
  emit("next", {
    params: { diet },
    nextScreenId: props.screen.nextStep,
  });
}
</script>

<template>
  <div>
    <h1>
      {{ $t("registration.dietary_preferences.headline") }}
    </h1>
    <div class="mb-4">{{ $t("registration.dietary_preferences.teaser") }}</div>
    <div class="grid gap-4">
      <OnboardingBtn
        emoji="🍗"
        translation-key="app.profunnel.funnel.dietary_preferences.no_preference"
        @click="selectOption(null)"
      />
      <OnboardingBtn
        emoji="🐟"
        translation-key="app.profunnel.funnel.dietary_preferences.pescatarian"
        @click="selectOption('pescatarian')"
      />
      <OnboardingBtn
        emoji="🧀"
        translation-key="app.profunnel.funnel.dietary_preferences.vegetarian"
        @click="selectOption('vegetarian')"
      />
      <OnboardingBtn
        emoji="🌱"
        translation-key="app.profunnel.funnel.dietary_preferences.vegan"
        @click="selectOption('vegan')"
      />
    </div>
  </div>
</template>
