<script setup lang="ts">
import { ref } from "vue";

const { locale } = useNuxtApp().$i18n;
const { code } = storeToRefs(useDiscountStore());
const helloFreshDiscountCode = "hf-wzu9p";
const isGermanHelloFreshCampaign = computed<boolean>(() => {
  return code.value === helloFreshDiscountCode && locale.value === "de";
});

const recipes = ref([
  {
    title: "Tex-Mex Fajita-Pfanne",
    image: "https://images.yazio.com/frontend/app-lp/partner/hellofresh-recipe-9009.jpg?w=700",
    description:
      "Deine Extraportion Eiweiß für starke Muskeln: Entdecke dieses leckere High Protein Gericht mit mindestens 20% Protein.",
  },
  {
    title: "Seehecht auf Curry-Linsengemüse",
    image: "https://images.yazio.com/frontend/app-lp/partner/hellofresh-recipe-9012.jpg?w=700",
    description:
      "Weniger Kohlenhydrate, voller Geschmack! Genieße dieses leckere Low Carb Gericht mit weniger als 50 g Kohlenhydraten pro Portion.",
  },
  {
    title: "Perlencouscous-Salat",
    image: "https://images.yazio.com/frontend/app-lp/partner/hellofresh-recipe-9013.jpg?w=700",
    description:
      "Für alle, die es frisch und bunt lieben: Mit mehr als 240 g Gemüse pro Portion ist dieses Gericht ein leckerer, ausgewogener Genuss.",
  },
]);
</script>

<template>
  <div v-if="isGermanHelloFreshCampaign" class="flex flex-col gap-4">
    <h1 class="m-0 text-center text-white">Entdecke die Fit & Vital Rezepte von HelloFresh</h1>
    <div>
      <div
        v-for="recipe in recipes"
        :key="recipe.title"
        class="mb-4 flex flex-col items-center rounded-xl bg-white md:flex-row"
      >
        <img
          :src="recipe.image"
          :alt="recipe.title"
          class="max-h-96 w-full rounded-t-xl object-cover md:h-auto md:w-60 md:rounded-none md:rounded-s-lg"
        />
        <div class="flex flex-col justify-between p-4 leading-none">
          <h2 class="xs:text-center mb-2 text-lg text-yz-blue-900 lg:text-left">
            {{ recipe.title }}
          </h2>
          <p class="xs:text-center mb-0 pb-0 font-normal leading-5 text-yz-blue-900 lg:text-left">
            {{ recipe.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
