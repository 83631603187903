<script lang="ts" setup>
import { ref } from "vue";
import AppIcon from "~/components/app/AppIcon.vue";
import OnboardingStickyButtonWrapper from "~/components/onboarding/OnboardingStickyButtonWrapper.vue";
import OnboardingBtn from "~/components/onboarding/OnboardingBtn.vue";
import type { MultiChoiceScreen, ScreenOption } from "~/types/onboarding/onboardingScreen";
import type { RegistrationParams } from "~/types/onboarding/registrationParams";

interface Props {
  screen: MultiChoiceScreen;
}

const { multiChoiceSelectedOptions } = storeToRefs(useOnboardingStore());
const props = defineProps<Props>();

interface Emits {
  (
    e: "next",
    value: {
      params: Partial<RegistrationParams>;
      nextScreenId: string;
    },
  ): void;
}

const emit = defineEmits<Emits>();

const selectedOptions = ref<string[]>([]);
const { addActionEvent } = useTrackingStore();

onMounted(() => {
  selectedOptions.value = getPersistedSelectedOptions();
});

function getPersistedSelectedOptions() {
  if (multiChoiceSelectedOptions.value[props.screen.id]) {
    return multiChoiceSelectedOptions.value[props.screen.id];
  }
  return [];
}

function setPersistedSelectedOptions() {
  multiChoiceSelectedOptions.value[props.screen.id] = selectedOptions.value;
}

function isSelected(key: string) {
  return selectedOptions.value.includes(key);
}

function selectOption(_option: ScreenOption, key: string) {
  const index = selectedOptions.value.indexOf(key);
  if (index !== -1) {
    selectedOptions.value.splice(index, 1);
  } else {
    selectedOptions.value.push(key);
  }
  setPersistedSelectedOptions();
}

function next() {
  emit("next", {
    params: {},
    nextScreenId: props.screen.nextStep,
  });

  addActionEvent({
    name: props.screen.eventName,
    selection: selectedOptions.value,
    screenType: props.screen.type,
  });
}
</script>
<template>
  <OnboardingStickyButtonWrapper :button-text="$t(screen.nextButtonTranslationKey)" @button-click="next">
    <h1>
      {{ $t(screen.titleTranslationKey) }}
    </h1>
    <div class="grid gap-4 pb-4">
      <OnboardingBtn
        v-for="option in screen.options"
        :key="option.trackingName"
        :emoji="option.emoji"
        :translation-key="option.translationKey"
        :description-translation-key="option.descriptionTranslationKey"
        :class="{ active: isSelected(option.trackingName) }"
        @click="selectOption(option, option.trackingName)"
      >
        <AppIcon
          :icon="isSelected(option.trackingName) ? 'mdi:checkbox-marked' : 'mdi:checkbox-blank-outline'"
          class="shrink-0"
          :class="isSelected(option.trackingName) ? 'text-yz-blue-600' : 'text-[#38425080]'"
          width="1.5rem"
        />
      </OnboardingBtn>
    </div>
  </OnboardingStickyButtonWrapper>
</template>
