<script lang="ts" setup>
interface Props {
  progress: number;
}

defineProps<Props>();

async function handleSkipButton() {
  await useOnboardingStore().skipOnboarding();
}
</script>

<template>
  <div id="progress-wrapper" class="flex items-center gap-5">
    <div class="h-1 grow overflow-hidden rounded-lg bg-delight-neutral-100" role="progressbar">
      <div class="h-1 rounded-lg bg-yz-blue-600 transition-all duration-300" :style="{ width: `${progress}%` }" />
    </div>

    <div>
      <div class="cursor-pointer text-xs text-delight-neutral-700" @click="handleSkipButton">
        {{ $t("text.webfunnel.skip_button") }}
      </div>
    </div>
  </div>
</template>
