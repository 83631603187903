<script lang="ts" setup>
const config = useRuntimeConfig();
interface Props {
  src: string;
  size?: "MEDIUM" | "LARGE";
  alignment?: "CENTER" | "BOTTOM";
}

const props = withDefaults(defineProps<Props>(), {
  size: "MEDIUM",
  alignment: "BOTTOM",
});

const fullUrl = computed(() => {
  const width = 1920;
  return `${config.public.imageBaseUrl}/${props.src}.png?w=${width}`;
});
</script>

<template>
  <div
    class="bg-cover bg-no-repeat"
    :class="[
      { 'h-[35vh]': size === 'MEDIUM', 'h-[45vh]': size === 'LARGE' },
      alignment === 'CENTER' ? 'bg-[center_center]' : 'bg-[center_bottom]',
    ]"
    :style="{
      backgroundImage: `url(${fullUrl})`,
    }"
  />
</template>
