<script lang="ts" setup>
import OnboardingStickyButtonWrapper from "~/components/onboarding/OnboardingStickyButtonWrapper.vue";
import type { HeightScreen } from "~/types/onboarding/onboardingScreen";
import type { RegistrationParams, UnitLength } from "~/types/onboarding/registrationParams";
import AppHeight from "~/components/app/AppHeight.vue";
import { useHeight } from "~/composables";

interface Props {
  screen: HeightScreen;
  registrationParams: RegistrationParams;
}

const props = defineProps<Props>();

interface Emits {
  (
    e: "next",
    value: {
      params: {
        bodyHeight: number;
        unitLength: UnitLength;
      };
      nextScreenId: string;
    },
  ): void;
}

const emit = defineEmits<Emits>();

const {
  cm,
  cmAsString,
  feetAsString,
  inchAsString,
  unitLength,
  validate,
  error,
  cmInvalid,
  feetInvalid,
  inchInvalid,
  canContinue,
} = useHeight(props.registrationParams.bodyHeight, props.registrationParams.unitLength);

function next() {
  const hasError = validate();
  if (hasError) return;
  emit("next", {
    params: {
      bodyHeight: cm.value as number,
      unitLength: unitLength.value,
    },
    nextScreenId: props.screen.nextStep,
  });
}
</script>

<template>
  <OnboardingStickyButtonWrapper
    :button-disabled="!canContinue"
    :button-text="$t('registration.general.next')"
    :enable-additional-button="true"
    @button-click="next"
  >
    <h1>
      {{ $t("registration.height.headline") }}
    </h1>
    <div class="mb-12">
      {{ $t("registration.height.info.teaser") }}
    </div>
    <AppHeight
      v-model:cm="cmAsString"
      v-model:feet="feetAsString"
      v-model:inch="inchAsString"
      v-model:unitLength="unitLength"
      :cm-invalid="cmInvalid"
      :ft-invalid="feetInvalid"
      :in-invalid="inchInvalid"
      :error-message="error"
      class="mb-4"
      @submit="next"
    />
  </OnboardingStickyButtonWrapper>
</template>
