<script setup lang="ts">
type Props = {
  style?: "button" | "close";
};

withDefaults(defineProps<Props>(), {
  style: "button",
});

async function skipOnboarding() {
  await useOnboardingStore().skipOnboarding();
}
</script>

<template>
  <button
    v-if="style === 'button'"
    id="quizSkipButton"
    class="w-full rounded-full border border-white px-6 py-4 font-medium text-white sm:w-3/4"
    @click="skipOnboarding"
  >
    {{ $t("text.webfunnel.skip_button") }}
  </button>
  <div v-else class="absolute right-8 top-8 cursor-pointer" @click="skipOnboarding">
    <img alt="" src="https://assets.yazio.com/frontend/images/web-funnels/layout/close-button-white.svg" />
  </div>
</template>
